import { promises as fs } from 'fs';
import path from 'path';
import { default as matter } from 'gray-matter';
import { Container } from '@components/layout/Grid';
import MetaTags from '@components/MetaTags';
import * as Sentry from '@sentry/nextjs';
import { ENABLED_LOCALS } from '@lib/constants';

const page = ({ pageProps }) => {
  const { content, metaData } = pageProps;

  return (
    <div className="text-base px-5">
      <MetaTags
        title={metaData.title}
        description={metaData.desc}
        thumbnailUrl={metaData.image}
      />
      <Container
        id="yg-content"
        className="font-light flex-col bg-[#f2f1f1]"
        dangerouslySetInnerHTML={{ __html: content }}
      ></Container>
    </div>
  );
};

const isValidFileName = (segment) =>
  /^[a-zA-Z0-9_-]+$/.test(segment) && !segment.includes('..');

const BASE_PATH = path.join(
  process.cwd(),
  'legacy_landing_pages/landing_pages'
);

function safeJoin(filename) {
  const targetPath = path.normalize(path.join(BASE_PATH, filename));
  // Ensure the target path is within the base directory
  if (!targetPath.startsWith(BASE_PATH)) {
    return null; // Indicate an unsafe path
  }
  return targetPath;
}

export const getServerSideProps = async (ctx) => {
  const { lang, params } = ctx.query;

  // WE REDIRECT FROM _APP BUT NEXT STILL CONTINUES RENDERING
  // THE COMPONENT/PAGE WHILE HEADERS ARE SENT TO THE BROWSER FOR REDIRECT
  if (!ENABLED_LOCALS.includes(lang)) {
    return {
      props: {},
    };
  }

  try {
    const filename = params.join('_');
    const filePath = safeJoin(`${lang}_${filename}.md`);

    if (!isValidFileName(filename) && safeJoin(filePath)) {
      return {
        notFound: true,
      };
    }

    const fileContent = await fs.readFile(filePath, 'utf8');

    const matterContent = matter(fileContent);

    return {
      props: {
        // because of prettier hook, the html will be prettified everytime, so we minify on request
        content: matterContent.content,
        metaData: matterContent.data,
      },
    };
  } catch (ex) {
    // Sentry.captureException(ex);

    return {
      notFound: true,
    };
  }
};

page.isLegacyPage = true;

export default page;
